import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";

import p4 from "../../Assets/Projects/P4.jpg";
import p3 from "../../Assets/Projects/P3.jpg";
import p1 from "../../Assets/Projects/P1.jpg";
import p5 from "../../Assets/Projects/P5.jpg";
import p2 from "../../Assets/Projects/P2.jpg";




function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p1}
              isBlog={false}
              title="
              Trebuchet Launcher"
              description="A trebuchet is a sort of catapult that throws a projectile with a long arm. Until the invention of gunpowder, it was a common and powerful siege engine. A trebuchet's design allows it to throw projectiles with higher weights over longer distances than a standard catapult"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p2}
              isBlog={false}
              title="Speed Detector"
              description="While improving road safety performance, the number of people killed and injured on our roads continues to be unacceptable. To support the new casualty reduction targets, a road safety strategy was launched. As a result, speed restrictions are an excellent idea. To enforce these speed limits, laws prohibiting speeding have been implemented, and signs have been constructed to show the maximum allowable speeds. Because the police cannot be everywhere to enforce the speed limit."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p3}
              isBlog={false}
              title="Handling Messy Wires on Desk"
              description="In our daily life, if we look at our desk, we can see that it gets dirty for a number of reasons, the most common of which are cables. Most individuals do not have time to organize their workstation cables, which can result in wire breakage, electric shocks, and other incidents caused by the wires"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p4}
              isBlog={false}
              title="AquaOil"
              description="The boat will collect spill oil from water and also filter the oil after collecting with the help of sensors and actuators."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p5}
              isBlog={false}
              title="Armadillo Shell to Shelters for Two-Wheelers"
              description="We can develop foldable bike shelters that take up less space and more durable than present shelters using the Armadillo shell concept.;"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
